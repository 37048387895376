.App {
  text-align: center;
  background-color: #38383b;
  min-height: 100vh;
}

.logo {
  height: 2rem;
  padding-left: 0.5rem;
}

.Header {
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  color: white;
}

.ban_info {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Roboto', sans-serif;
}


.ban_info_line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.ban_info_line > code {
  margin-left: 0.5rem;
}

.skeleton_val {
  margin-left: 1rem;
  width: 2rem;
}